#components-layout-demo-top-side-2 .logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.ant-row-rtl #components-layout-demo-top-side-2 .logo {
  float: right;
  margin: 16px 0 16px 24px;
}

.sider-layout-background {
  background: #fff !important;
}

.content-layout-background :global {
  background: #fff !important;
}

.mainLayout {
  height: calc(100vh - 60px);
}
