.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  line-height: 60px;
  background-color: #4f87e7;
  padding: 0 30px;
}

.title {
  color: #fff !important;
  margin-bottom: 0 !important;
}
.username,
.login {
  color: #fff !important;
  font-size: 18px;
}
.divider {
  width: 2px;
  height: 16px;
  background: #f5efef;
  margin: 0 20px;
}
.login {
  cursor: pointer;
}
